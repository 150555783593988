import { action, computed, observable, runInAction } from 'mobx';
import {
  actionsRenderer,
  allRenderer,
  auditRenderer,
  expandCollapseRenderer,
  FailedQualificationsModalContent,
  labelRenderer,
  MessageAdditionalSetupRequired,
  MessageDuplicateComputerNames,
  MessageInsufficientDiskCSpace,
  MessageMultipleServersDetected,
  MessageNeedHelp,
  MessageNoServerDetected,
  MessageRemoveOverride,
  MessageSendInstructionsFailed,
  MessageSendInstructionsSent,
  statusRenderer,
} from './renderers';
import dayjs from 'dayjs';
import type { SortingObservable } from '../../components/Grid/Grid';
import { GridColDef } from '../../components/Grid/Grid';
import { refreshCheckQualificationsConfig } from '../../config/app';
import { formatMBytes, isDefined, sleep, sortByField } from '../../utils/object-utils';
import type { AgentData } from '../../types/practice-types';
import { AuditStatus, isAgentUpgradeQualified, UpgradeStatus } from '../../types/practice-types';
import { AGENTS_PATH } from '../../store/constants';
import AbstractUpgradeTaskStore from '../../store/AbstractUpgradeTaskStore';
import React from 'react';
import { regexToSearchWithWildcards } from '../../utils/search-utils';

interface Loaders {
  page?: boolean;
  label?: boolean;
  status?: boolean;
  pending?: boolean;
  confirm?: boolean;
  sendInstructions?: boolean;
}

export default class CheckQualificationsStore extends AbstractUpgradeTaskStore {
  @observable loaders: Loaders = { };

  @observable findComputersPage = true;

  @observable showInstallationInstructions = false;

  @observable expandedRowIds: string[] = [];

  @observable editLabelIndex: number | null = null;

  @observable searchText = '';

  @observable hideDoNotUpgrade = false;

  @observable ensurePoweredOnPromptShown = false;

  @observable sorting: SortingObservable<AgentData> = {};

  @observable vsaHost = '';

  @observable intervalHandles: Record<string, {
    handle: ReturnType<typeof setInterval>;
    attemptsUsed: number;
  } | undefined> = {};

  @action.bound
  public switchExpandedRow(row: AgentData): void {
    if (this.expandedRowIds.includes(row.agentId)) {
      this.expandedRowIds = this.expandedRowIds.filter(id => id !== row.agentId);
    } else {
      this.expandedRowIds = [...this.expandedRowIds, row.agentId];
      if (row.auditStatus === AuditStatus.UNQUALIFIED && isDefined(row.qualifications)) {
        this.root.globalStore.showOkPopup(
          'Failed Qualification Checks',
          React.createElement(FailedQualificationsModalContent, { qualifications: row.qualifications })
        );
      }
    }
  }

  @computed
  public get agentsData(): AgentData[] {
    return this.root.globalStore.agentsData;
  }

  public set agentsData(agentsData) {
    this.root.globalStore.agentsData = agentsData;
  }

  @computed
  public get duplicateAgentsToUpgradeNames(): string[] {
    const allSet = new Set<string>();
    const dupSet = new Set<string>();
    this.agentsData.filter(a => a.status === UpgradeStatus.UPGRADE || a.status === UpgradeStatus.UPGRADE_MANUALLY_APPROVED).forEach(a => {
      const name = a.name;
      if (allSet.has(name)) {
        dupSet.add(name);
      } else {
        allSet.add(name);
      }
    });
    return Array.from(dupSet);
  }

  @computed
  public get colDefs(): GridColDef[] {
    return [
      { headerName: '', field: 'expand', renderer: expandCollapseRenderer(this.switchExpandedRow), sortable: false },
      { headerName: 'All', field: 'all', renderer: allRenderer, sortable: false, checked: this.allChecked, setChecked: this.setAllChecked },
      { headerName: 'Qualification Check', field: 'auditStatus', renderer: auditRenderer, sortable: true },
      { headerName: 'Type', field: 'type', sortable: true },
      { headerName: 'Nickname', field: 'label', renderer: labelRenderer, sortable: true },
      { headerName: 'Machine Name', field: 'name', sortable: true },
      { headerName: 'Set Up', field: 'status', renderer: statusRenderer, sortable: true },
      { headerName: 'Actions', field: 'actions', renderer: actionsRenderer, sortable: false },
    ];
  }

  @action
  public async init(): Promise<void> {
    super.init();
    runInAction(() => {
      this.loaders.page = true;
      this.findComputersPage = this.agentsData.length === 0;
      this.showInstallationInstructions = false;
    });
    if (this.isCloudPractice || this.isOldCornerstone || this.serverWithInsufficientFreeSpaceOnDriveC) {
      await this.fetchAgents();
    } else {
      runInAction(() => this.loaders.page = false);
      await this.checkServerAgents(this.findComputersPage);
      await this.checkDuplicateAgentNames();
    }
    this.checkIntervalHandle(true);
    const minTimestamp = Math.min(...this.agentsData.map(d => d.cachedTimestamp));
    if (minTimestamp < dayjs().subtract(1, 'day').valueOf()) {
      await this.root.globalStore.showOkCancelPopupAsync(
        'Refresh List',
        `The information about your computers was last updated ${dayjs().diff(dayjs(minTimestamp), 'days')} days ago. We recommend refreshing the list of computers`,
        'Refresh List',
        'Decline',
        () => this.fetchAgents(),
      );
    }
  }

  @action
  public onChangeSapId(): void {
    this.ensurePoweredOnPromptShown = false;
  }

  @action
  public close(): void {
    super.close();
    this.searchText = '';
    this.hideDoNotUpgrade = false;
    this.sorting = {};
    this.root.globalStore.setLoading(false);
    this.root.globalStore.globalModalProps.show = false;
    this.agentsData.forEach(a => a.checked = false);
  }

  @computed
  public get agentsDataFiltered(): AgentData[] {
    let filtered = this.agentsData;
    if (this.searchText !== '') {
      const regex = regexToSearchWithWildcards(this.searchText);
      filtered = this.agentsData.filter(a => regex.test(a.label || ''));
    }
    if (this.hideDoNotUpgrade) {
      filtered = filtered.filter(a => a.status === UpgradeStatus.UPGRADE);
    }
    if (this.sorting.field) {
      filtered = sortByField(filtered, this.sorting.field);
      if (this.sorting.order === 'DESC') {
        filtered = filtered.reverse();
      }
    }
    return filtered;
  }

  @computed
  public get checkedRowsCount(): number {
    return this.agentsData.filter(d => d.checked).length;
  }

  @computed
  public get allChecked(): boolean {
    return this.agentsData.some(d => d.checked);
  }

  @computed
  public get auditLoading(): boolean {
    return this.agentsData.some(d => d.auditLoading);
  }

  @computed
  public get noUpgradeCount(): number {
    return this.agentsData.filter( d => d.status === UpgradeStatus.DO_NOT_UPGRADE).length;
  }

  @computed
  public get upgradeCount(): number {
    return this.agentsData.length - this.noUpgradeCount;
  }

  @action.bound
  public setAllChecked(value: boolean): void {
    if (value) {
      this.agentsData.filter(d => d.status === UpgradeStatus.UPGRADE && d.auditStatus !== AuditStatus.PENDING).forEach(a => a.checked = true);
    } else {
      this.agentsData.forEach(a => a.checked = false);
    }
  }

  @computed
  public get isCloudPractice(): boolean {
    return this.agentsData.some(d => d.isCloud);
  }

  @computed
  public get isOldCornerstone(): boolean {
    return this.agentsData.some(d => !d.qualifications?.csVersion);
  }

  @computed
  public get serverWithInsufficientFreeSpaceOnDriveC(): AgentData | undefined {
    return this.serverAgents.find(d => !d.qualifications?.freeSpaceOnDriveC);
  }

  @computed
  public get isProcessingRow(): boolean {
    return this.agentsData.some(d => d.auditStatus === AuditStatus.PENDING);
  }

  @computed
  public get serverAgents(): AgentData[] {
    return this.agentsData.filter(d => d.type === 'Server');
  }

  @computed
  public get serversToUpgrade(): number {
    return this.serverAgents.filter(d => d.status !== UpgradeStatus.DO_NOT_UPGRADE).length;
  }

  @computed
  public get upgradeButtonEnabled(): boolean {
    return this.serversToUpgrade === 1
      && this.agentsData.every(a => a.status === UpgradeStatus.DO_NOT_UPGRADE || isAgentUpgradeQualified(a))
      && this.duplicateAgentsToUpgradeNames.length === 0;
  }

  @action
  public async findMyComputers(): Promise<void> {
    this.root.globalStore.setLoading(true);
    try {
      const agentsKaseyaInfo = await this.root.apiStore.getAgentsKaseyaInfo(this.sapId, true);
      runInAction(() => this.vsaHost = agentsKaseyaInfo.vsaHost);
      if (agentsKaseyaInfo.isMachineGroupExists) {
        await this.fetchAgents();
        if (this.agentsData.length === 0) {
          this.switchToInstallationInstructions();
        }
      } else {
        this.root.globalStore.showOkPopup(
          'Additional Setup Required',
          React.createElement(MessageAdditionalSetupRequired),
        );
      }
    } catch (err: any) {
      runInAction(() => this.agentsData = []);
      this.showConnectionErrorPopup(2);
    }
    this.root.globalStore.setLoading(false);
  }

  @action
  public async fetchAgents(): Promise<void> {
    this.root.globalStore.setLoading(true);
    try {
      void this.audit('UPGRADE_TASK', 'CHECK_QUALIFICATIONS_PAGE_REFRESH_LIST');
      const agentsData = await this.root.apiStore.getRefreshedAgents(this.sapId);
      this.updateAgentsData(agentsData);
    } catch (err: any) {
      runInAction(() => this.agentsData = []);
      this.showConnectionErrorPopup(2);
    } finally {
      runInAction(() => this.loaders.page = false);
    }
    this.checkIntervalHandle();
    this.root.globalStore.setLoading(false);
    if (this.isCloudPractice) {
      await this.root.globalStore.showOkPopupAsync(
        'Notification',
        'Cornerstone Cloud practice cannot schedule an upgrade through this service. Please call Cornerstone Support',
        () => this.navigate('/')
      );
    } else if (this.isOldCornerstone) {
      if (this.agentsData.every(d => !d.qualifications?.csVersion)) {
        await this.root.globalStore.showOkPopupAsync(
          'Notification',
          'Based on your current Cornerstone version, an upgrade cannot be scheduled through this service. Please call Cornerstone Support',
          () => this.navigate('/')
        );
      } else {
        await this.root.globalStore.showOkPopupAsync(
          'Unsupported Version Detected',
          'Only computers with Cornerstone 9.1 or higher can be upgraded using MyCornerstone.',
        );
      }
    } else if (this.serverWithInsufficientFreeSpaceOnDriveC) {
      const agent = this.serverWithInsufficientFreeSpaceOnDriveC;
      const requiredSpace = formatMBytes(agent.diskCRequiredFreeSpace);
      await this.root.globalStore.showOkPopupAsync(
        'Notification',
        React.createElement(MessageInsufficientDiskCSpace(agent.name, requiredSpace)));
    }
    await this.checkServerAgents(this.findComputersPage);
    await this.checkDuplicateAgentNames();
    runInAction(() => this.findComputersPage = this.agentsData.length === 0);
  }

  @action
  public async initiateCheck(agent?: AgentData): Promise<void> {
    const agentIds: string[] = [];
    runInAction(() => {
      if (agent) {
        agent.auditLoading = true;
        agent.checked = false;
        agentIds.push(agent.agentId);
      } else {
        this.agentsData.forEach(a => {
          if (a.checked) {
            a.auditLoading = true;
            a.checked = false;
            agentIds.push(a.agentId);
          }
        });
      }
    });
    if (!this.ensurePoweredOnPromptShown) {
      this.root.globalStore.showOkPopup(
        'Checking Qualifications',
        'While the check is running, ensure the selected computer(s) remain powered on and connected to the internet'
      );
      runInAction(() => this.ensurePoweredOnPromptShown = true);
    }
    try {
      void this.audit('UPGRADE_TASK', 'CHECK_QUALIFICATIONS_PAGE_INITIATE_CHECK');
      const agentsData = await this.root.apiStore.backendPost<AgentData[]>(AGENTS_PATH, {
        action: 'RUN_AUDIT',
        sapId: this.sapId,
        agentIds,
      });
      runInAction(() => {
        agentsData.forEach(a => {
          const index = this.agentsData.findIndex(agent => agent.agentId === a.agentId);
          if (index >= 0) {
            this.agentsData[index] = a;
          }
        });
      });
    } catch (err: any) {
      this.showConnectionErrorPopup(agentIds.length);
    }
    runInAction(() => this.agentsData.forEach(a => a.auditLoading = false));
    this.checkIntervalHandle();
  }

  @action
  public async scheduleUpgrade(): Promise<void> {
    void this.audit('UPGRADE_TASK', 'CHECK_QUALIFICATIONS_PAGE_SCHEDULE_UPGRADE');
    runInAction(() => this.loaders.confirm = true);
    await this.root.globalStore.fetchPracticeData(false);
    this.navigate('/schedule-upgrade');
    runInAction(() => this.loaders.confirm = false);
  }

  @action
  public async exitUpgrade(): Promise<void> {
    void this.audit('UPGRADE_TASK', 'CHECK_QUALIFICATIONS_PAGE_EXIT_UPGRADE');
    this.navigate('/');
  }

  @action
  private checkIntervalHandle(refreshNow = false): void {
    if (!this.intervalHandles[this.sapId] && this.agentsData.some(a => a.auditStatus === AuditStatus.PENDING)) {
      this.intervalHandles[this.sapId] = {
        handle: setInterval(this.refreshAgentsStatus, refreshCheckQualificationsConfig.interval, this.sapId),
        attemptsUsed: 0,
      };
      if (refreshNow) {
        void this.refreshAgentsStatus(this.sapId);
      }
    }
  }

  @action.bound
  public async refreshAgentsStatus(sapId: string): Promise<void> {
    const handlerObj = this.intervalHandles[sapId];
    if (!handlerObj) return;
    handlerObj.attemptsUsed++;
    const lastAttempt = handlerObj.attemptsUsed >= refreshCheckQualificationsConfig.maxAttempts;
    runInAction(() => this.loaders.pending = true);
    try {
      const agentsData = await this.root.apiStore.getAgentsRefreshStatus(sapId, lastAttempt);
      this.updateAgentsData(agentsData);
    } catch (err: any) {
      // ignore
    }
    runInAction(() => this.loaders.pending = false);
    if (lastAttempt || this.agentsData.every(a => a.auditStatus !== AuditStatus.PENDING)) {
      clearInterval(this.intervalHandles[sapId]?.handle);
      runInAction(() => this.intervalHandles[this.sapId] = undefined);
    }
  }

  @action
  public async updateLabel(newLabel: string): Promise<void> {
    if (this.editLabelIndex !== null) {
      runInAction(() => this.loaders.label = true);
      try {
        void this.audit('UPGRADE_TASK', 'CHECK_QUALIFICATIONS_PAGE_UPDATE_AGENT_LABEL');
        await this.root.apiStore.backendPost<AgentData[]>(AGENTS_PATH, {
          action: 'UPDATE_LABEL',
          sapId: this.sapId,
          agentId: this.agentsData[this.editLabelIndex].agentId,
          label: newLabel,
        });
        // @ts-ignore
        runInAction(() => this.agentsData[this.editLabelIndex].label = newLabel);
      } catch (err: any) {
        // ignore
      } finally {
        runInAction(() => {
          this.editLabelIndex = null;
          this.loaders.label = false;
        });
      }
    }
  }

  @action
  public switchStatus(itemIndex: number): void {
    if (this.agentsDataFiltered[itemIndex].status === UpgradeStatus.UPGRADE_MANUALLY_APPROVED) {
      this.root.globalStore.showOkCancelPopup(
        'Exclude From Upgrade?',
        React.createElement(MessageRemoveOverride),
        'Continue',
        'Cancel',
        () => this.doSwitchStatus(itemIndex),
      );
    } else {
      void this.doSwitchStatus(itemIndex);
    }
  }

  @action
  public async doSwitchStatus(itemIndex: number): Promise<void> {
    const newStatus = this.agentsDataFiltered[itemIndex].status === UpgradeStatus.DO_NOT_UPGRADE ? UpgradeStatus.UPGRADE : UpgradeStatus.DO_NOT_UPGRADE;
    runInAction(() => this.loaders.status = true);
    try {
      void this.audit('UPGRADE_TASK', 'CHECK_QUALIFICATIONS_PAGE_SWITCH_AGENT_STATUS');
      await this.root.apiStore.backendPost<AgentData[]>(AGENTS_PATH, {
        action: 'UPDATE_STATUS',
        sapId: this.sapId,
        agentId: this.agentsDataFiltered[itemIndex].agentId,
        status: newStatus,
      });
      runInAction(() => this.agentsDataFiltered[itemIndex].status = newStatus);
      const agent = this.agentsDataFiltered[itemIndex];
      if (newStatus === UpgradeStatus.UPGRADE && this.duplicateAgentsToUpgradeNames.includes(agent.name)) {
        await this.showDuplicateComputerNamesPopup();
      }
    } catch (err: any) {
      this.showConnectionErrorPopup(1);
    }
    runInAction(() => this.loaders.status = false);
  }

  @action
  public async switchStatusToOverride(itemIndex: number): Promise<void> {
    runInAction(() => this.loaders.status = true);
    try {
      await this.root.apiStore.backendPost<AgentData[]>(AGENTS_PATH, {
        action: 'UPDATE_STATUS',
        sapId: this.sapId,
        agentId: this.agentsDataFiltered[itemIndex].agentId,
        status: UpgradeStatus.UPGRADE_MANUALLY_APPROVED,
      });
      const agent = this.agentsDataFiltered[itemIndex];
      runInAction(() => agent.status = UpgradeStatus.UPGRADE_MANUALLY_APPROVED);
      if (this.duplicateAgentsToUpgradeNames.includes(agent.name)) {
        void this.showDuplicateComputerNamesPopup();
      }
    } catch (err: any) {
      this.showConnectionErrorPopup(1);
    }
    runInAction(() => this.loaders.status = false);
  }

  @action
  public async sendIdentificationAlert(itemIndex: number): Promise<void> {
    const row = this.agentsDataFiltered[itemIndex];
    runInAction(() => row.rowLoading = true);
    try {
      void this.audit('UPGRADE_TASK', 'CHECK_QUALIFICATIONS_PAGE_FIND_ME');
      const agent = await this.root.apiStore.getAgent(this.sapId, row.agentId);
      if (![1, 2, 11, 12].includes(agent.online)) {
        this.root.globalStore.showOkPopup(
          'Find Me - Alert not sent',
          'Unable to send alert. Computer is offline or unavailable.',
        );
        return;
      }
      await this.root.apiStore.backendPost<AgentData[]>(AGENTS_PATH, {
        action: 'SEND_IDENTIFICATION_ALERT',
        sapId: this.sapId,
        agentId: row.agentId,
      });
      await sleep(10000); // 10 seconds
      this.root.globalStore.showOkPopup(
        'Find Me - Alert sent',
        'An alert has been sent. A pop up message and a text file will display on the destination computer\'s desktop.',
      );
    } catch (err: any) {
      this.showConnectionErrorPopup(1);
    } finally {
      runInAction(() => row.rowLoading = false);
    }
  }

  @action
  private updateAgentsData(newAgentsData: AgentData[]): void {
    const map: Record<string, AgentData> = {};
    this.agentsData.forEach(a => map[a.agentId] = a);
    newAgentsData.forEach(a => a.checked = map[a.agentId]?.checked || false);
    this.agentsData = newAgentsData;
  }

  @action
  public showNeedHelpModal(): void {
    this.root.globalStore.showOkPopup(
      'How To - Instructions',
      React.createElement(MessageNeedHelp),
    );
  }

  public openKaseyaURL(): void {
    window.open(`https://${this.vsaHost}/deploy/#/idexx.${this.sapId}.root`, '_blank');
  }

  @action
  public async sendInstallationInstructions(): Promise<void> {
    if (this.loaders.sendInstructions) return;
    runInAction(() => this.loaders.sendInstructions = true);
    try {
      await this.root.apiStore.sendInstallationInstructions(this.sapId);
      this.root.globalStore.showOkPopup(
        'Email Sent',
        React.createElement(MessageSendInstructionsSent(this.root.apiStore.userEmail)),
      );
    } catch {
      this.root.globalStore.showOkPopup(
        'Email Failed',
        React.createElement(MessageSendInstructionsFailed),
      );
    }
    runInAction(() => this.loaders.sendInstructions = false);
  }

  @action
  public switchToInstallationInstructions(): void {
    this.findComputersPage = true;
    this.showInstallationInstructions = true;
  }

  private showConnectionErrorPopup(count: number): void {
    this.root.globalStore.showOkPopup(
      'Connection error',
      `Unable to connect to your computer${count > 1 ? '(s)' : ''}, please try again later`,
    );
  }

  private showDuplicateComputerNamesPopup(): Promise<void> {
    return this.root.globalStore.showOkPopupAsync(
      'Duplicate Computer Names',
      React.createElement(MessageDuplicateComputerNames),
    );
  }

  private async checkServerAgents(findComputers?: boolean): Promise<void> {
    if (this.agentsData.length > 0 && this.serverAgents.length === 0) {
      await this.root.globalStore.showOkPopupAsync(
        'No Server Detected',
        React.createElement(MessageNoServerDetected),
        () => findComputers && this.navigate('/'),
      );
    } else if (this.serverAgents.length > 1) {
      await this.root.globalStore.showOkPopupAsync(
        'Multiple Servers Detected',
        React.createElement(MessageMultipleServersDetected),
        () => findComputers && this.navigate('/'),
      );
    }
  }

  private async checkDuplicateAgentNames(): Promise<void> {
    if (this.duplicateAgentsToUpgradeNames.length > 0) {
      await this.showDuplicateComputerNamesPopup();
    }
  }
}
